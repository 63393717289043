
  import { Component, Vue } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { PurchaseOrder } from '@/entities/purchase'
  import { mapGetters } from 'vuex'
  import { fixAmountFormatted } from '@/utils/general'
  import { DateGenerator } from '@/utils/date/DateGenerator'

@Component({
  computed: {
    ...mapGetters('app', ['system']),
  },
})
  export default class consignmentWithdrawalPDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  documentType: Record<string, any>
  document: Record<string, any>
  consignmentDocumentType: Record<string, any>
  consignmentDocument: Record<string, any>
  template: Record<string, any>
  disabledPdf = true
  fields: Record<string, any>

  system!: string

  async mounted () {
    const { item: { id } } = this
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id } },
      force: true,
    })

    this.documentType = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { name: { _eq: 'consignment_withdrawal_contract' } },
    }))[0]

    this.document = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        id_process_record: { _eq: this.purchaseOrder.id },
        id_document_type: { _eq: this.documentType.id },
      },
    }))[0]

    this.disabledPdf = this.purchaseOrder.acquisitionType.name !== 'consignment' || this.purchaseOrder.status.status.name !== 'approved' || !this.document
  }

  async generatePDF () {
    this.loading = true

    this.template = this.documentType.template

    this.consignmentDocumentType = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { name: { _eq: 'consignment_contract' } },
    }))[0]

    this.consignmentDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        id_process_record: { _eq: this.purchaseOrder.id },
        id_document_type: { _eq: this.consignmentDocumentType.id },
      },
    }))[0]

    console.log('DOCUMENT', this.document)
    console.log('CONSIGNMENT DOCUMENT', this.consignmentDocument)
    console.log('DOCUMENT TYPE', this.documentType)
    console.log('FIELDS', this.documentType.fields.map(x => x.name))
    console.log('TEMPLATE', this.template)
    console.log('PURCHASE ORDER', this.purchaseOrder)

    this.purchaseOrder.negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id: this.purchaseOrder.negotiation.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal.stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { deals: { id: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } } },
    })

    await this.getFields()

    const pdfContent = this.$refs.pdfContent
    const element = pdfContent instanceof HTMLElement ? pdfContent : (pdfContent as Vue).$el as HTMLElement

    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a4')
    this.setFont(doc)

    // Coordenadas iniciales
    const x = 10
    const y = 20

    doc.setLineWidth(400)

    // Llenar el PDF con el contenido
    await this.page1(doc, y, x)

    // Convertir el contenido del HTML a texto en el PDF
    this.addHtmlContent(doc, element, x, y)
  }

  async getFields () {
    const company = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    }))[0]
    const companyAddress = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: company.id } },
    }))[0]
    const { address } = companyAddress

    const mileageComponent = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: {
        slug: { _eq: 'mileage' },
      },
    }))[0]
    const mileage = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        id_component: { _eq: mileageComponent.id },
        id_deal: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id },
      },
    }))[0]

    const seller = this.consignmentDocument.interveners.filter(person => person.field.name === 'seller')[0].person
    const paymentOrder = (await this.fetchData({
      query: { name: 'find', model: 'PaymentOrder' },
      filter: { id_deal: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
    }))[0]
    const ticketPaymentOrderItems = paymentOrder.items.filter(item => item.processExpense.expense.name === 'traffic_ticket_payment')

    let ticketsCost = 0
    ticketPaymentOrderItems.forEach(item => ticketsCost += item.amount)

    const executive = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.executive.person

    const metadata = this.consignmentDocument.metadata

    const consignmentDays = metadata.consignment_period.days
    const consignmentExpiracyDate = this.purchaseOrder.createdAt.add(consignmentDays, 'day')

    this.fields = {
      city: `${address.city.name}, región ${address.city.region.name}`.toUpperCase(),
      consignment_contract_start_contract: 'las ' + this.consignmentDocument.createdAt.format('HH:mm, DD/MM/YYYY'),
      consignment_contract_seller_name: `${seller.firstName}${seller.secondName ? ' ' : ''}${seller.secondName || ''} ${seller.surname}${seller.secondSurname ? ' ' : ''}${seller.secondSurname || ''}`.toUpperCase(),
      consignment_contract_seller_uid: seller.uid,
      company_name: `${company.companyName}, rol ${company.uid}`.toUpperCase(),
      company_address: `${address.streetType.description} ${address.streetName} ${address.streetNumber}`.toUpperCase(),
      auto: `${metadata.auto} año ${metadata.year} patente ${metadata.ppu}`,
      consignment_expiracy_date: consignmentExpiracyDate.format('DD/MM/YYYY'),
      mileage: fixAmountFormatted(mileage.value),
      executive: `${executive.firstName}${executive.secondName ? ' ' : ''}${executive.secondName || ''} ${executive.surname}${executive.secondSurname ? ' ' : ''}${executive.secondSurname || ''}`.toUpperCase(),
      consignment_contract_consignment_period: consignmentDays,
      consignment_income: '[CONSIGNMENT INCOME]',
      start_contract: 'el ' + this.document.createdAt.format('DD/MM/YYYY'),
    }
  }

  replaceVariable (text, variableName, value) {
    return text.split(`$${variableName}$`).join(value).split('\'').join('"')
  }

  findVariableNames (text) {
    const variables = []
    text.split(' ').forEach(word => {
      const splitted = word.split('')
      if (splitted[0] === '$') {
        variables.push(word.split('$')[1])
      }
    })
    return variables
  }

  addIntroduction (doc, x, y) {
    const { paragraphs } = this.template

    paragraphs.forEach(paragraph => {
      const variables = this.findVariableNames(paragraph)
      let newText = paragraph

      variables.forEach(variable => {
        newText = this.replaceVariable(newText, variable, this.fields[variable])
      })

      y = this.addParagraph(doc, x, y, newText)
    })

    return y
  }

  async addList (doc, x, y) {
    const { list } = this.template

    await list.forEach(async (item, index) => {
      const variables = this.findVariableNames(item.text)
      let newText = item.text

      variables.forEach(variable => {
        newText = this.replaceVariable(newText, variable, this.fields[variable])
      })

      newText = newText
        .split(' ,').join(',')
        .split(' .').join('.')

      if (y > doc.internal.pageSize.getHeight() - 60) {
        y = this.newPage(doc, y)
        await this.addClosingSection(doc)
      }
      this.addBoldText(doc, x, y, index + 1 + '.')
      y = this.applyTextWithStyles(doc, x + 12, y, `|b|${item.title}|/b| ${newText}`) + 3
    })
    return y + 17
  }

  addClosure (doc: jsPDF, x: number, y: number): number {
    const { closure } = this.template
    y = this.addParagraph(doc, x, y, closure)
    return y
  }

  addSignSection (doc: jsPDF, y) {
    const declaration = 'Firma y acepta las condiciones'
    const clientName = this.purchaseOrder.negotiation.inspection.deal.lead.client.fullName
    const clientUid = this.purchaseOrder.negotiation.inspection.deal.lead.client.uid

    let height = (y + doc.internal.pageSize.getHeight() - 30) / 2

    doc.setDrawColor('#0033A5')
    doc.line(60, height, doc.internal.pageSize.getWidth() - 60, height)
    height += 5
    doc.text(declaration, this.getCenterWidth(doc, declaration), height)
    height += 5
    doc.setFont(undefined, 'bold')
    doc.text(clientName, this.getCenterWidth(doc, clientName), height)
    height += 5
    doc.text(clientUid, this.getCenterWidth(doc, clientUid), height)
    height += 7
    return height
  }

  async addClosingSection (doc: jsPDF) {
    const executive = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.executive
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })
    this.addClosing(doc, [
        'Asesor comercial:',
        `${executive.person.firstName} ${executive.person.surname}`,
        `Tel: ${executive.person.phoneWork || 'No informado'}`,
        executive.person.email.work || 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  async page1 (doc: jsPDF, y, x) {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = await this.loadImage(system.photo)
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      doc.addImage(imageData, 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }

    this.footer(doc)
    await this.addClosingSection(doc)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, this.template.title) + 3
    y = this.addIntroduction(doc, x, y)
    y = await this.addList(doc, x, y)
    y = this.addClosure(doc, x, y)
    this.addSignSection(doc, y)
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`retiro consignacion ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.fullName} ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  findComponentInfo (component, inspection) {
    const matchingInspectedComponent = inspection.inspectedComponents?.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )
    if (!matchingInspectedComponent) return
    const date = DateGenerator.findGeneratedDate(matchingInspectedComponent.findInspectionParameterByOrder(2).value).internal

    const name = matchingInspectedComponent.findInspectionParameterByOrder(1).value

    return { date, name }
  }

  findComponentBySlug (inspectionComponents, slug) {
    return inspectionComponents?.find(component => component.slug === slug)
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
